import React from "react";

function SvgMenu({ title = null, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 20"
      fill="none"
      css={`
        color: ${props.color};
        width: ${props.size};
        height: ${props.size};
        vertical-align: text-top;
      `}
      data-pka-anchor="icon"
      focusable={false}
      {...props}
    >
      {title ? <title>{title}</title> : null}
      <path d="M3 4v2h14V4H3zM17 11H3V9h14v2zM17 16H3v-2h14v2z" fill="currentColor" />
    </svg>
  );
}

export default SvgMenu;
