// Do not edit this file, it is automatically generated by @paprika/build-translations
    const locales = {
  "fr": {
    "translation": {
      "timePicker": {
        "am": "am",
        "custom": "Personnalisé",
        "hours": "Heures",
        "minutes": "Minutes",
        "period": "Période",
        "pm": "pm"
      },
      "back": "Retour",
      "remove": "supprimer",
      "remove_a11y": "Supprimer {{value}}",
      "moreInformation": "plus d'informations",
      "close": "Fermer",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. Vous êtes désormais dans la ligne {{rowIndex}}, colonne {{columnIndex}}. Ignorez les informations suivantes :",
        "rows": "Lignes : {{rowCount}}",
        "columns": "Colonnes : {{columnCount}}"
      },
      "uploader": {
        "drop_files": "Déposer les fichiers à charger",
        "drop_files_here_or": "Déposer les fichiers à charger ici ou",
        "choose_from_computer": "parcourir votre ordinateur",
        "choose_from_computer_a11y": "Choisir des fichiers dans votre ordinateur",
        "cancel_upload": "Annuler le chargement pour {{name}}",
        "restart_upload": "Sélectionner à nouveau {{name}} pour réessayer",
        "file_list": "Liste des fichiers",
        "errors": {
          "size": "La taille du fichier ne doit pas dépasser {{maxFileSize}}",
          "type": "Le fichier doit être de l'un des types suivants : {{- supportedTypes}}"
        },
        "status": {
          "idle": "Inactif",
          "processing": "Traitement en cours",
          "uploading": "Téléchargement",
          "success": "Réussite",
          "cancel": "Annuler",
          "error": "Erreur"
        },
        "progress": {
          "cancelled": "Annulé(e)",
          "complete": "Terminé(e)",
          "error": "Une erreur est survenue pour {{name}}: {{error}}",
          "idle": "Inactif",
          "file_progress": "{{name}} est {{progress}}",
          "uploading_percent": "Chargement en cours, {{a11yProgress}}%",
          "uploading": "Chargement en cours de {{progressWithUnits}} sur {{sizeWithUnits}}"
        },
        "size_abbreviations": {
          "byte": "O",
          "kibibyte": "Kio",
          "mebibyte": "Mio",
          "gibibyte": "Gio",
          "tebibyte": "Tio"
        }
      },
      "actions": {
        "delete": "Supprimer",
        "apply": "Appliquer",
        "cancel": "Annuler",
        "confirm": "Enregistrer",
        "decline": "Refuser"
      },
      "datePicker": {
        "confirmation_format": "D MMMM YYYY"
      },
      "calendar": {
        "header_format": "MMMM YYYY",
        "month": "Mois",
        "year": "Année"
      },
      "daterange": {
        "last_7_days": "7 derniers jours",
        "last_30_days": "30 derniers jours",
        "this_month": "Ce mois",
        "last_month": "Le mois dernier",
        "start_date": "Date de début",
        "end_date": "Date de fin"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} s'ouvre dans un nouvel onglet."
      },
      "comment": {
        "delete": "Supprimer le commentaire"
      },
      "pagination": {
        "intro": "Pagination, actuellement à la page {{currentPage}}",
        "page": "Page {{pageNumber}}",
        "ellipsis": "Plus de pages",
        "prev": "Page précédente",
        "next": "Page suivante"
      },
      "formElement": {
        "optional": "(facultatif)",
        "required": "(requis)",
        "aria_info_circle": "Informations"
      },
      "prevNextArrowButton": {
        "arrowup": "haut",
        "arrowdown": "bas",
        "escape": "Échap"
      },
      "collapsible": {
        "ariaText": "Section réductible",
        "collapse": "Réduire",
        "expand": "Développer"
      },
      "sortable": {
        "aria_description": "Élément déplaçable. Appuyez sur la touche espace pour lever.",
        "aria_lift": "Vous avez élevé un élément dans la position {{source}}. Utilisez les touches de direction pour déplacer, la touche Espace pour déposer et Échap pour annuler.",
        "aria_moving": "Vous avez déplacé l'élément de la position {{source}} vers la position {{destination}}.",
        "aria_moving_outside": "Vous n'êtes pas en train de faire glisser un élément sur une zone déposable.",
        "aria_cancelled": "Mouvement annulé. L'élément est retourné à sa position initiale de {{source}}.",
        "aria_dropped": "Vous avez déposé l'élément. Il est déplacé de la position {{source}} vers {{destination}}.",
        "aria_dropped_outside": "L'élément a été déposé alors qu'il n'était au-dessus de la liste. L'élément est retourné à sa position initiale de {{source}}.",
        "aria_remove": "Supprimer"
      },
      "progressAccordion": {
        "active": "actif(ve)",
        "no_response": "Aucun"
      },
      "collapsibleText": {
        "more": "Afficher plus",
        "less": "Afficher moins",
        "more_about": "Afficher plus sur {{topic}}",
        "less_about": "Afficher moins sur {{topic}}"
      },
      "listBox": {
        "trigger": {
          "clear_selection": "Effacer la sélection",
          "placeholder": "Sélectionner..."
        },
        "filter": {
          "a11y_text": "Rechercher",
          "no_results_message": "Aucun résultat trouvé",
          "placeholder": "Rechercher..."
        },
        "footer": {
          "accept": "Appliquer",
          "cancel": "Annuler",
          "clear": "Effacer"
        }
      },
      "listBoxBrowser": {
        "explore": "Découvrir d'autres options",
        "selected": "options sélectionnées",
        "selectOne": "Sélectionner un ou plusieurs éléments..."
      },
      "listBoxWithTags": {
        "placeholder": "Sélectionner...",
        "no_results_found": "Aucun résultat trouvé",
        "all_items_have_been_selected": "Tous les éléments ont été sélectionnés",
        "a11y_text_trigger": "Option multi-sélection : {{options}}"
      },
      "search": {
        "searchTerm": "Rechercher le terme..."
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "Masquer tout",
          "show_all": "Afficher tout",
          "label": "Organiser",
          "singular_label": "1 colonne masquée",
          "plural_label": "{{numberOfHiddenColumn}} colonnes masquées",
          "search_placeholder": "Rechercher..."
        },
        "no_results": "Pas de résultats",
        "search_placeholder": "Rechercher...",
        "search_a11y_text": "Rechercher les données",
        "sort": {
          "no_sorts_applied": "Aucun tri appliqué à cette vue",
          "label": "Trier",
          "singular_label": "1 colonne triée",
          "plural_label": "{{numberOfFields}} colonnes triées",
          "add_field": "Ajouter un champ pour trier par",
          "sort_by": "Trier par",
          "then_by": "alors par",
          "rules": {
            "ascending": {
              "boolean": "Tri croissant (vrai → faux)",
              "number": "Tri croissant (1 → 9)",
              "text": "Tri croissant (A → Z)",
              "date": "Tri croissant (1 → 9)",
              "single_select": "Tri croissant (A → Z)"
            },
            "descending": {
              "boolean": "Tri décroissant (faux → vrai)",
              "number": "Tri décroissant (9 → 1)",
              "text": "Tri décroissant (Z → A)",
              "date": "Tri décroissant (9 → 1)",
              "single_select": "Tri décroissant (Z → A)"
            }
          }
        }
      },
      "breadcrumbs": {
        "aria_label": "Fils d'Ariane",
        "aria_expand": "Afficher tous les éléments du fil d'Ariane",
        "aria_back_to": "Retour à"
      },
      "sideNavigation": {
        "aria_label": "Navigation latérale",
        "trigger_aria_label": "Ouvrir la navigation"
      },
      "copyInput": {
        "clicked_tooltip": "Copié",
        "hover_tooltip": "Copier dans le presse-papiers"
      },
      "dynamicHyperlinkTransformer": {
        "access_denied": "Accès refusé",
        "invalid_url": "URL non valide",
        "exceeded_max_links_rendered": "Restitution impossible",
        "loading": "Chargement..."
      },
      "filter": {
        "actions": {
          "add": "Ajouter un filtre",
          "clear": "Effacer",
          "delete": "Supprimer"
        },
        "no_filters_applied": "Aucun filtre appliqué à cette vue",
        "label": "Filtre",
        "label_with_count": "{{count}} filtré(e/s)",
        "and": "Et",
        "or": "Ou",
        "rules": {
          "is": "est",
          "is_not": "n'est pas",
          "contains": "contient",
          "does_not_contain": "ne contient pas",
          "is_blank": "est vide",
          "is_not_blank": "n'est pas vide",
          "equals": "est égal à",
          "not_equal_to": "n'est pas égal à",
          "greater_than": ">",
          "less_than": "<",
          "greater_than_or_equal_to": "≥",
          "less_than_or_equal_to": "≤",
          "is_empty": "est vide",
          "is_not_empty": "n'est pas vide",
          "is_before": "est avant",
          "is_after": "est après",
          "is_one_of": "appartient à",
          "is_not_one_of": "n'appartient pas à",
          "true": "vrai",
          "false": "faux"
        }
      },
      "input": {
        "clear_button": {
          "aria_label": "Effacer l'entrée"
        }
      }
    }
  }
};
    export default locales;