// Do not edit this file, it is automatically generated by @paprika/build-translations
    const locales = {
  "ja": {
    "translation": {
      "timePicker": {
        "am": "午前",
        "custom": "カスタム",
        "hours": "時間",
        "minutes": "分",
        "period": "期間",
        "pm": "午後"
      },
      "back": "戻る",
      "remove": "削除",
      "remove_a11y": "{{value}}を削除",
      "moreInformation": "詳しい情報",
      "close": "閉じる",
      "dataGrid": {
        "a11yTextMessage": "{{value}}. 行 {{rowIndex}}、列 {{columnIndex}} の位置にいます。次の情報は無視してください。",
        "rows": "行: {{rowCount}}",
        "columns": "列: {{columnCount}}"
      },
      "uploader": {
        "drop_files": "アップロードするファイルをドロップ",
        "drop_files_here_or": "アップロードするファイルをここにドロップまたは",
        "choose_from_computer": "コンピューターから選択",
        "choose_from_computer_a11y": "コンピューターからファイルを選択する",
        "cancel_upload": "{{name}}のアップロードをキャンセル",
        "restart_upload": "{{name}}をもう一度選択し、再試行します",
        "file_list": "ファイル一覧",
        "errors": {
          "size": "ファイルは{{maxFileSize}}未満にする必要があります",
          "type": "ファイルは次のタイプの1つにする必要があります: {{- supportedTypes}}"
        },
        "status": {
          "idle": "アイドル状態",
          "processing": "処理中",
          "uploading": "アップロード中",
          "success": "成功",
          "cancel": "キャンセル",
          "error": "エラー"
        },
        "progress": {
          "cancelled": "取り消されました",
          "complete": "完了",
          "error": "{{name}にエラーが発生しました}: {{error}}",
          "idle": "アイドル状態",
          "file_progress": "{{name}}は{{progress}}です",
          "uploading_percent": "アップロード、{{a11yProgress}}%",
          "uploading": "{{sizeWithUnits}}のうち{{progressWithUnits}}をアップロード"
        },
        "size_abbreviations": {
          "byte": "B",
          "kibibyte": "KiB",
          "mebibyte": "MiB",
          "gibibyte": "GiB",
          "tebibyte": "TiB"
        }
      },
      "actions": {
        "delete": "削除",
        "apply": "適用",
        "cancel": "キャンセル",
        "confirm": "保存",
        "decline": "辞退"
      },
      "datePicker": {
        "confirmation_format": "YYYY 年 M 月 D 日"
      },
      "calendar": {
        "header_format": "YYYY 年 M 月",
        "month": "月",
        "year": "年"
      },
      "daterange": {
        "last_7_days": "過去 7 日間",
        "last_30_days": "過去 30 日間",
        "this_month": "今月",
        "last_month": "先月",
        "start_date": "開始日",
        "end_date": "終了日"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} が新しいタブで開きます。"
      },
      "comment": {
        "delete": "コメントを削除"
      },
      "pagination": {
        "intro": "改ページ位置の自動修正、現在は {{currentPage}} ページ",
        "page": "{{pageNumber}} ページ",
        "ellipsis": "その他のページ",
        "prev": "前ページ",
        "next": "次ページ"
      },
      "formElement": {
        "optional": "(省略可能)",
        "required": "(必須)",
        "aria_info_circle": "情報"
      },
      "prevNextArrowButton": {
        "arrowup": "上",
        "arrowdown": "下",
        "escape": "エスケープ"
      },
      "collapsible": {
        "ariaText": "縮小可能セクション",
        "collapse": "折りたたむ",
        "expand": "展開する"
      },
      "sortable": {
        "aria_description": "ドラッグ可能な項目。スペースを押して取り除きます。",
        "aria_lift": "{{source}}のポジションにある項目を 1 つ取り除きました。移動するには矢印、ドロップするにはスペース、そしてキャンセルするにはエスケープ キーを使用します。",
        "aria_moving": "{{source}}のポジションから{{destination}}のポジションまで項目を移動しました。",
        "aria_moving_outside": "現在ドラッグしているのは、ドラッグ可能な領域ではありません。",
        "aria_cancelled": "移動がキャンセルされました。項目は、{{source}}の出発点に戻りました。",
        "aria_dropped": "項目をドロップしました。{{source}}のポジションから{{destination}}のポジションまで項目が移動しました。",
        "aria_dropped_outside": "一覧にない項目がドロップされました。項目は、{{source}}の出発点に戻りました。",
        "aria_remove": "削除"
      },
      "progressAccordion": {
        "active": "アクティブ",
        "no_response": "なし"
      },
      "collapsibleText": {
        "more": "詳しく表示",
        "less": "少なく表示",
        "more_about": "{{topic}}について詳しく表示",
        "less_about": "{{topic}}について少なく表示"
      },
      "listBox": {
        "trigger": {
          "clear_selection": "選択をクリア",
          "placeholder": "選択..."
        },
        "filter": {
          "a11y_text": "検索",
          "no_results_message": "結果が見つかりません",
          "placeholder": "検索..."
        },
        "footer": {
          "accept": "適用",
          "cancel": "キャンセル",
          "clear": "クリア"
        }
      },
      "listBoxBrowser": {
        "explore": "より多くのオプションを探す",
        "selected": "選択されたオプション",
        "selectOne": "1 つ以上の項目を選択..."
      },
      "listBoxWithTags": {
        "placeholder": "選択…",
        "no_results_found": "結果が見つかりません",
        "all_items_have_been_selected": "すべての項目が選択されています",
        "a11y_text_trigger": "多数選択オプション: {{options}}"
      },
      "search": {
        "searchTerm": "用語の検索..."
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "すべて非表示",
          "show_all": "すべて表示",
          "label": "調整",
          "singular_label": "1列非表示",
          "plural_label": "{{numberOfHiddenColumn}}列非表示",
          "search_placeholder": "検索..."
        },
        "no_results": "結果なし",
        "search_placeholder": "検索...",
        "search_a11y_text": "データを検索",
        "sort": {
          "no_sorts_applied": "このビューに適用する並べ替えなし",
          "label": "並べ替え",
          "singular_label": "1列並べ替え済み",
          "plural_label": "{{numberOfFields}}列並べ替え済み",
          "add_field": "ソート対象のフィールドを追加",
          "sort_by": "並べ替え条件",
          "then_by": "次の条件",
          "rules": {
            "ascending": {
              "boolean": "昇順 (真 → 偽)",
              "number": "昇順 (1 → 9)",
              "text": "昇順 (A → Z)",
              "date": "昇順 (1 → 9)",
              "single_select": "昇順 (A → Z)"
            },
            "descending": {
              "boolean": "降順 (偽 → 真)",
              "number": "降順 (9 → 1)",
              "text": "降順 (Z → A)",
              "date": "降順 (9 → 1)",
              "single_select": "降順 (Z → A)"
            }
          }
        }
      },
      "breadcrumbs": {
        "aria_label": "階層リンク",
        "aria_expand": "すべての階層リンク項目を表示します。",
        "aria_back_to": "戻る:"
      },
      "sideNavigation": {
        "aria_label": "スライドのナビゲーション",
        "trigger_aria_label": "ナビゲーションを開く"
      },
      "copyInput": {
        "clicked_tooltip": "コピー済み",
        "hover_tooltip": "クリップボードにコピー"
      },
      "dynamicHyperlinkTransformer": {
        "access_denied": "アクセスが拒否されました",
        "invalid_url": "URL が無効です",
        "exceeded_max_links_rendered": "レンダリングできませんでした",
        "loading": "読み込み中..."
      },
      "filter": {
        "actions": {
          "add": "フィルターを追加",
          "clear": "クリア",
          "delete": "削除"
        },
        "no_filters_applied": "このビューに適用するフィルターなし",
        "label": "フィルター",
        "label_with_count": "{{count}}件フィルター済み",
        "and": "および",
        "or": "または",
        "rules": {
          "is": "=",
          "is_not": "は次でない:",
          "contains": "は次を含む:",
          "does_not_contain": "は次を含まない:",
          "is_blank": "は空白である",
          "is_not_blank": "は空白でない",
          "equals": "は次と一致する:",
          "not_equal_to": "は次と一致しない:",
          "greater_than": ">",
          "less_than": "<",
          "greater_than_or_equal_to": "≥",
          "less_than_or_equal_to": "≤",
          "is_empty": "は空である",
          "is_not_empty": "は空でない",
          "is_before": "は次より前である:",
          "is_after": "は次より後である:",
          "is_one_of": "は次の1つである:",
          "is_not_one_of": "は次の1つでない:",
          "true": "真",
          "false": "偽"
        }
      },
      "input": {
        "clear_button": {
          "aria_label": "入力をクリア"
        }
      }
    }
  }
};
    export default locales;