import React from "react";
import { FocusPropTypes } from "@paprika/helpers";

const propTypes = FocusPropTypes;

const FocusLock = () => <></>;

FocusLock.propTypes = propTypes;
FocusLock.displayName = "Panel.FocusLock";

export default FocusLock;
