// Do not edit this file, it is automatically generated by @paprika/build-translations
    const locales = {
  "zh": {
    "translation": {
      "timePicker": {
        "am": "上午",
        "custom": "自定义",
        "hours": "小时",
        "minutes": "分钟",
        "period": "期间",
        "pm": "下午"
      },
      "back": "返回",
      "remove": "移除",
      "remove_a11y": "移除 {{value}}",
      "moreInformation": "更多信息",
      "close": "关闭",
      "dataGrid": {
        "a11yTextMessage": "{{value}}。您在第 {{rowIndex}} 行，第 {{columnIndex}} 列。忽略以下信息：",
        "rows": "行：{{rowCount}}",
        "columns": "列：{{columnCount}}"
      },
      "uploader": {
        "drop_files": "放下文件以上传",
        "drop_files_here_or": "在此放下文件以上传或",
        "choose_from_computer": "从您的电脑中选择",
        "choose_from_computer_a11y": "从您的电脑中选择文件",
        "cancel_upload": "取消上传{{name}}",
        "restart_upload": "再次选择 {{name}} 重试",
        "file_list": "文件列表",
        "errors": {
          "size": "文件必须小于 {{maxFileSize}}",
          "type": "文件必须是以下类型之一：{{- supportedTypes}}"
        },
        "status": {
          "idle": "空闲",
          "processing": "正在处理",
          "uploading": "上传中",
          "success": "成功",
          "cancel": "取消",
          "error": "错误"
        },
        "progress": {
          "cancelled": "已取消",
          "complete": "完成",
          "error": "{{name}} 出错了：{{error}}",
          "idle": "空闲",
          "file_progress": "{{name}} 正在 {{progress}}",
          "uploading_percent": "上传中，{{a11yProgress}}%",
          "uploading": "正在上传 {{progressWithUnits}} / {{sizeWithUnits}}"
        },
        "size_abbreviations": {
          "byte": "B",
          "kibibyte": "KiB",
          "mebibyte": "MiB",
          "gibibyte": "GiB",
          "tebibyte": "TiB"
        }
      },
      "actions": {
        "delete": "删除",
        "apply": "应用",
        "cancel": "取消",
        "confirm": "保存",
        "decline": "拒绝"
      },
      "datePicker": {
        "confirmation_format": "YYYY 年 M 月 D 日"
      },
      "calendar": {
        "header_format": "YYYY 年 M 月",
        "month": "月",
        "year": "年"
      },
      "daterange": {
        "last_7_days": "过去 7 天",
        "last_30_days": "过去 30 天",
        "this_month": "本月",
        "last_month": "上月",
        "start_date": "开始日期",
        "end_date": "结束日期"
      },
      "dropdownMenu": {
        "isExternal": "{{link}} 在新选项卡中打开。"
      },
      "comment": {
        "delete": "删除评论"
      },
      "pagination": {
        "intro": "分页，当前在第 {{currentPage}} 页",
        "page": "页 {{pageNumber}}",
        "ellipsis": "更多页面",
        "prev": "上一页",
        "next": "下一页"
      },
      "formElement": {
        "optional": "（可选）",
        "required": "（必须）",
        "aria_info_circle": "信息"
      },
      "prevNextArrowButton": {
        "arrowup": "上",
        "arrowdown": "下",
        "escape": "Esc 键"
      },
      "collapsible": {
        "ariaText": "可折叠部分",
        "collapse": "折叠",
        "expand": "展开"
      },
      "sortable": {
        "aria_description": "可拖动项目。按空格键提起。",
        "aria_lift": "您已将一个项目提起到位 {{source}}。使用箭头键移动，使用空格键放下，使用 Esc 键取消。",
        "aria_moving": "您已将项目从位置{{source}移动到位置{{destination}。",
        "aria_moving_outside": "您当前不是在可拖放区域上拖动。",
        "aria_cancelled": "已取消移动。项目已返回到其{{source}}的起始位置。",
        "aria_dropped": "您已放下该项目。它已从位置{{source}移到{{destination}。",
        "aria_dropped_outside": "该项目不在列表上方时已被放下。项目已返回到其{{source}}的起始位置。",
        "aria_remove": "移除"
      },
      "progressAccordion": {
        "active": "活动的",
        "no_response": "无"
      },
      "collapsibleText": {
        "more": "显示更多",
        "less": "显示更少",
        "more_about": "显示关于{{topic}}的更多信息",
        "less_about": "显示关于{{topic}}的更少信息"
      },
      "listBox": {
        "trigger": {
          "clear_selection": "清除选择",
          "placeholder": "选择…"
        },
        "filter": {
          "a11y_text": "搜索",
          "no_results_message": "未找到结果",
          "placeholder": "搜索…"
        },
        "footer": {
          "accept": "应用",
          "cancel": "取消",
          "clear": "清除"
        }
      },
      "listBoxBrowser": {
        "explore": "探索更多选项",
        "selected": "选定选项",
        "selectOne": "选择一项或多项…"
      },
      "listBoxWithTags": {
        "placeholder": "选择…",
        "no_results_found": "未找到结果",
        "all_items_have_been_selected": "已选择所有项",
        "a11y_text_trigger": "多选选项：{{options}}"
      },
      "search": {
        "searchTerm": "搜索词…"
      },
      "actionBar": {
        "columns_arrangement": {
          "hide_all": "全部隐藏",
          "show_all": "全部显示",
          "label": "排列",
          "singular_label": "隐藏 1 列",
          "plural_label": "隐藏 {{numberOfHiddenColumn}} 列",
          "search_placeholder": "搜索…"
        },
        "no_results": "没有结果",
        "search_placeholder": "搜索…",
        "search_a11y_text": "搜索数据",
        "sort": {
          "no_sorts_applied": "没有排序应用于此视图",
          "label": "排序",
          "singular_label": "已排序 1 列",
          "plural_label": "已排序 {{numberOfFields}} 列",
          "add_field": "添加排序字段",
          "sort_by": "排序依据",
          "then_by": "随之排序依据",
          "rules": {
            "ascending": {
              "boolean": "升序排序（真 → 假）",
              "number": "升序排序 (1 → 9)",
              "text": "升序排序 (A → Z)",
              "date": "升序排序 (1 → 9)",
              "single_select": "升序排序 (A → Z)"
            },
            "descending": {
              "boolean": "降序排序（假 → 真）",
              "number": "降序排序 (9 → 1)",
              "text": "降序排序 (Z → A)",
              "date": "降序排序 (9 → 1)",
              "single_select": "降序排序 (Z → A)"
            }
          }
        }
      },
      "breadcrumbs": {
        "aria_label": "面包屑",
        "aria_expand": "显示所有面包屑项目。",
        "aria_back_to": "返回"
      },
      "sideNavigation": {
        "aria_label": "侧边导航",
        "trigger_aria_label": "打开导航"
      },
      "copyInput": {
        "clicked_tooltip": "已复制",
        "hover_tooltip": "复制到剪贴板"
      },
      "dynamicHyperlinkTransformer": {
        "access_denied": "拒绝访问",
        "invalid_url": "无效的 URL",
        "exceeded_max_links_rendered": "无法呈现",
        "loading": "正在加载…"
      },
      "filter": {
        "actions": {
          "add": "添加过滤器",
          "clear": "清除",
          "delete": "删除"
        },
        "no_filters_applied": "没有过滤器应用于此视图",
        "label": "过滤器",
        "label_with_count": "{{count}} 个已过滤",
        "and": "和",
        "or": "或",
        "rules": {
          "is": "是",
          "is_not": "不是",
          "contains": "包含",
          "does_not_contain": "不包含",
          "is_blank": "为空",
          "is_not_blank": "不为空",
          "equals": "等于",
          "not_equal_to": "不等于",
          "greater_than": ">",
          "less_than": "<",
          "greater_than_or_equal_to": "≥",
          "less_than_or_equal_to": "≤",
          "is_empty": "是空的",
          "is_not_empty": "不是空的",
          "is_before": "早于",
          "is_after": "晚于",
          "is_one_of": "属于",
          "is_not_one_of": "不属于",
          "true": "真",
          "false": "假"
        }
      },
      "input": {
        "clear_button": {
          "aria_label": "清除输入"
        }
      }
    }
  }
};
    export default locales;